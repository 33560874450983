// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "assets/themes/colors";

// Plus imports for other components in your app.

// Imports for Bootstrap
// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// Optional
@import "bootstrap/scss/type";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";

/* SWIPER */
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$custom-typography: mat-typography-config(
  $font-family: "'Lato', sans-serif",
);

@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-primary: (
  main: $primary-color,
  lighter: $primary-color-light,
  darker: $primary-color-dark,
  200: $primary-color,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-accent: (
  main: $accent-color,
  lighter: $accent-color-light,
  darker: $accent-color-dark,
  200: $accent-color,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-contest-blue: (
  main: #004aad,
  lighter: #2d79e2,
  darker: #004094,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-contest-orange: (
  main: #e06f19,
  lighter: #ff8742,
  darker: #d04629,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-international-accent: (
  main: #6181ff,
  lighter: #7d97ff,
  darker: #4c70ff,
  200: #6181ff,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-international-primary: (
  main: #ecbd00,
  lighter: #f7d960,
  darker: #caa200,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-ceaden-primary: (
  main: #eba718,
  lighter: #efb948,
  darker: #c08811,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-ceaden-accent: (
  main: #e78e10,
  lighter: #f2a840,
  darker: #d2810f,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-impulso-primary: (
  main: #ecbd00,
  lighter: #f1d561,
  darker: #d4aa00,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-impulso-accent: (
  main: #feaf2e,
  lighter: #fcbe5a,
  darker: #fc9b00,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$campus-adenu-panama-primary: mat.define-palette(
  $mat-primary,
  main,
  lighter,
  darker
);
$campus-adenu-panama-accent: mat.define-palette(
  $mat-accent,
  main,
  lighter,
  darker
);
$campus-adenu-panama-contest-blue: mat.define-palette(
  $mat-contest-blue,
  main,
  lighter,
  darker
);
$campus-adenu-panama-contest-orange: mat.define-palette(
  $mat-contest-orange,
  main,
  lighter,
  darker
);

$campus-adenu-panama-international-primary: mat.define-palette(
  $mat-international-primary,
  main,
  lighter,
  darker
);
$campus-adenu-panama-international-secondary: mat.define-palette(
  $mat-international-accent,
  main,
  lighter,
  darker
);

$campus-ceaden-accent: mat.define-palette(
  $mat-ceaden-accent,
  main,
  lighter,
  darker
);

$campus-ceaden-primary: mat.define-palette(
  $mat-ceaden-primary,
  main,
  lighter,
  darker
);

$campus-impulso-accent: mat.define-palette(
  $mat-impulso-accent,
  main,
  lighter,
  darker
);

$campus-impulso-primary: mat.define-palette(
  $mat-impulso-primary,
  main,
  lighter,
  darker
);

// The warn palette is optional (defaults to red).
$campus-adenu-panama-warn: mat.define-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$campus-adenu-panama-theme: mat.define-light-theme(
  (
    color: (
      primary: $campus-adenu-panama-primary,
      accent: $campus-adenu-panama-accent,
      warn: $campus-adenu-panama-warn,
    ),
  )
);

$contest-theme: mat.define-light-theme(
  $campus-adenu-panama-contest-blue,
  $campus-adenu-panama-contest-orange
);

$international-theme: mat.define-light-theme(
  $campus-adenu-panama-international-primary,
  $campus-adenu-panama-international-secondary
);

$ceaden-theme: mat.define-light-theme(
  $campus-ceaden-primary,
  $campus-ceaden-accent
);

$impulso-theme: mat.define-light-theme(
  $campus-impulso-primary,
  $campus-impulso-accent
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($campus-adenu-panama-theme);

/* You can add global styles to this file, and also import other style files */

/* RESET */
button:focus {
  outline: 0 !important;
}

* {
  font-variant-ligatures: no-common-ligatures !important;
}

*:focus {
  outline: none !important;
  border: 0 !important;
}

a {
  color: $primary-color;

  &:hover {
    color: $primary-color-dark;
  }
}

button,
.mat-flat-button,
.mat-button,
.mat-stroked-button {
  min-height: 42px !important;
  min-width: 42px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px !important;

  .mat-button-wrapper {
    min-height: 42px !important;
    min-width: 42px !important;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  mat-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;
  }
}

.mat-mini-fab,
.mat-icon-button {
  .mat-button-wrapper {
    min-height: 42px !important;
    min-width: 42px !important;
    display: flex !important;
    gap: 0.5rem;
    align-items: center !important;
    justify-content: center !important;
    vertical-align: middle !important;
  }
}

button.mat-tab-header-pagination {
  border-radius: 0 !important;
}

.mat-form-field-outline {
  &-end {
    border-radius: 0 8px 8px 0 !important;
  }

  &-start {
    border-radius: 8px 0 0 8px !important;
  }
}

hr {
  border-top-color: #f1f1f1;
}

html,
body {
  background-color: #f9f9f9;
  height: 100%;
  margin: 0;
  font-size: 16px;

  scroll-behavior: smooth;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
}

.full-width {
  width: 100% !important;
}

.fill {
  flex: 1 !important;
}

.navigation-user-menu + * .mat-menu-panel {
  padding: 1rem;
  border-radius: 20px !important;
  max-width: 90vw !important;
  min-width: 200px;
  width: 300px;
  border-radius: 20px;
  box-shadow: $shadow-800;
}

.navigation-user-menu + * .mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

button.quick-link {
  width: 100%;
  border: none;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.quick-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1rem 0.8rem 0.5rem;
  color: #4f4f4f;
  font-size: 1rem;
  border-radius: 10px;
  text-decoration: none;
  transition: all ease-out;
  mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #a8a8a8;
  }

  &:hover {
    background-color: $primary-color-pastel-light;
    color: $primary-color;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    mat-icon {
      color: $primary-color;
    }
  }
}

.bg-primary-aden {
  background-color: $primary-color !important;
}

.bg-blocked {
  background-color: #f8423f !important;
}

.bg-disabled {
  background-color: $primary-color-light !important;
}

.text-primary-aden {
  color: $primary-color;
}

/* CHROME SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  // display: none;
}
::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}
.cdk-overlay-container {
  z-index: 999999999999999999999999999999999 !important;
}

.basic-alert .mat-dialog-container {
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
  @media screen and (max-width: 420px) {
    max-width: 90vw !important;
    max-height: 90vh !important;
  }
}

.generic-alert .mat-dialog-container {
  padding: 0 0 1rem 0;
  overflow: hidden;
  border-radius: 20px;

  @media screen and (max-width: 365px) {
    max-width: 100vw !important;
    max-height: 100vh !important;
  }
}

.cart-dialog .mat-dialog-container {
  padding: 0 0 0 0;
  overflow: hidden;
  border-radius: 20px;
  height: 90vh;
  width: 80vw;
  max-width: 100vw !important;
  max-height: 100vh !important;

  @media (max-width: 1440px) {
    width: 90vw;
  }

  @media (max-width: 420px) {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0px !important;
  }
}

.plain-alert .mat-dialog-container {
  padding: 2rem;
  overflow: hidden;
  border-radius: 20px;

  @media screen and (max-width: 365px) {
    max-width: 90vw !important;
    max-height: 90vh !important;
  }
}

.mat-tab-label-active {
  color: $primary-color !important;
  opacity: 1 !important;
  font-weight: bold !important;
}

.mat-button .border-blue {
  border: $primary-color solid 1px !important;
}

.mat-button.mat-primary {
  font-weight: 700;
}

#contest,
#dialog-contest {
  @include angular-material-theme($contest-theme);

  .primary {
    color: #004aad;
  }

  .secondary {
    color: #e06f19;
  }

  .mat-tab-label.mat-tab-label-active {
    color: #e06f19;
    font-weight: bold;
    opacity: 1;
  }

  .mat-ink-bar {
    background-color: transparent !important;
    &::before {
      content: "";
      display: block;
      width: 50%;
      background-color: #e06f19;
      height: 3px;
      border-radius: 3px;
      left: 25%;
      position: relative;
      top: -1px;
      z-index: 99;
    }
  }
}

#adenu-international,
#dialog-international {
  @include angular-material-theme($international-theme);
  .mat-tab-label.mat-tab-label-active {
    color: #6181ff;
    font-weight: bold;
    opacity: 1;
  }

  .mat-expansion-panel-header {
    padding: 40px 25px !important;
  }

  .primary {
    color: #ecbd00;
  }

  .secondary {
    color: #6181ff;
  }

  .red-dark {
    color: #810f09;
  }
  box-sizing: border-box;
}

.blue-snackbar {
  background: #2d79e2;
  color: #ffffff;

  button {
    color: #ffffff;
  }
}

.indigo-snackbar {
  background: #6181ff;
  color: #ffffff;

  button {
    color: #ffffff;
    font-weight: 900;
  }
}

.orange-snackbar {
  background: #e06f19;
  color: #ffffff;

  button {
    color: #ffffff;
  }
}

.red-snackbar {
  background: #e0194b;
  color: #ffffff;

  button {
    color: #ffffff;
  }
}

.mat-radio-label {
  margin-bottom: 0px;
}

.mat-chip {
  background-color: $primary-color-pastel !important;
  color: $primary-color !important;
}

.mat-standard-chip .mat-chip-remove.mat-icon {
  color: $primary-color !important;
  opacity: 1 !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}
.mat-checkbox-inner-container {
  margin-top: 4px;
}

.tooltip-style {
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 0.8rem;
}

.asignatura-tooltip {
  max-width: 200px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 0.9rem;
  background-color: #6181ff;
  color: #ffffff;
}

.material-icons-round.search {
  color: #a8a8a8 !important;
  fill: #a8a8a8;
}

/* MATERIAL GLOBAL*/

mat-option {
  line-height: normal !important;
  height: max-content !important;
  padding: 1rem !important;
}

div.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 0;
    margin-left: 0;
  }

  @media (min-width: 768px) {
    max-width: calc(100% - 2rem);
  }
  /* @media (min-width: 992px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  } */
  @media (min-width: 1300px) {
    max-width: calc(100% - 4rem);
  }
  @media (min-width: 1700px) {
    max-width: calc(100% - 8rem);
  }
}

/* ALERTAS */

.soonContainer {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.06);
  z-index: 99999;

  .soonMessage {
    min-width: 300px;
    min-height: 300px;
    max-width: 30vw;
    display: flex;
    position: sticky;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #ffffff;
    text-align: center;
    padding: 2rem;
    overflow-y: scroll;
    border-radius: 20px;

    @media screen and (max-width: 1500px) {
      max-width: 50vw;
    }

    @media screen and (max-width: 924px) {
      max-width: 80vw;
    }

    @media screen and (max-width: 768px) {
      max-width: 90vw;
    }

    h2 {
      font-size: 1.5rem;
      color: $primary-color;
      font-weight: 700;
    }

    p {
      font-size: 1rem;
    }

    img {
      width: 250px;
      margin-bottom: 1rem;
      @media screen and (max-width: 425px) {
        width: 180px;
      }
      @media screen and (max-width: 320px) {
        width: 100px;
      }
    }

    a {
      text-decoration: none;

      :hover {
        text-decoration: none;
      }
    }

    .button-container {
      display: flex;
      flex-direction: row-reverse;

      @media screen and (max-width: 425px) {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }

  .contract {
    width: 600px;
  }
}

.blur {
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
}

.paginator-container {
  background-color: #ffffff;
}

.mat-ink-bar {
  background-color: transparent !important;
  &::before {
    content: "";
    display: block;
    width: 50%;
    background-color: $primary-color;
    height: 3px;
    border-radius: 3px;
    left: 25%;
    position: relative;
    top: -1px;
    z-index: 99;
  }
}

.mat-tab-nav-bar,
.mat-tab-header {
  box-shadow: inset 0px -1px 0px #e5e5e5;
  border-bottom: none !important;
  a:hover {
    color: $primary-color-dark;
  }

  a:active {
    color: $primary-color;
  }
}

/*Common animations*/
.bounce {
  -webkit-animation: bounce 2.72s ease;
  animation: bounce 2.72s ease;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* LOADER CIRCLES */

.sk-fading-circle {
  width: 14px;
  height: 14px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.success-snackbar {
  background-color: #4caf50;
  color: #fff;
  z-index: 999999999999999;

  button {
    color: #ffffff;
    font-weight: 900;
  }
}

.error-snackbar {
  background-color: #fc2958;
  color: #fff;
  z-index: 999999999999999;

  button {
    color: #ffffff;
    font-weight: 900;
  }
}

.credit-tooltip {
  background-color: #fc2958 !important;
  font-size: 1rem;
  line-height: 140%;
  padding: 1rem !important;
  font-weight: bold !important;
}

.info-tooltip {
  background: $bold-gradient !important;
  font-size: 1rem;
  text-align: center;
  padding: 1rem !important;
  font-weight: bold !important;
}

.mat-tab-label {
  padding: 0.5rem 1.5rem !important;
  box-sizing: content-box !important;
}

.mat-tab-label.mat-tab-label-active {
  padding: 0.5rem 1.5rem;
  box-sizing: content-box;
}

#comite-alumnos {
  .mat-tab-nav-bar,
  .mat-tab-header {
    box-shadow: none;
  }
}

#ceaden {
  @include angular-material-theme($ceaden-theme);

  /* height: calc(100vh - 80px - 50px); */

  mat-tab-group {
    border-bottom: none;
    color: transparent;
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    box-shadow: none;
  }

  .mat-ink-bar::before {
    background-color: #eba718;
  }
}

#impulso {
  font-size: 16px;
  @include angular-material-theme($impulso-theme);

  .mat-ink-bar::before {
    background-color: $impulso-primary;
  }

  .mat-tab-label-active {
    color: $impulso-primary !important;
    opacity: 1 !important;
    font-weight: bold !important;
  }

  .header-bg {
    background: $impulso-gradient !important;
  }
}

/* TOUR */

.mat-card-title .title-text {
  font-size: 1.1rem !important;
  text-overflow: unset;
  font-weight: 900;
  color: $primary-color;
}

.tour-step {
  margin: 1rem;
}

/* NOT FOUND */
.notfound-small-container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  overflow-y: scroll;
  background-color: #fafafa;

  .spinner {
    margin: 0 auto;
  }

  img {
    max-width: 200px;
    width: 100%;
    max-height: 275px;
    height: auto;
    margin-bottom: 1rem;

    @media (max-width: 487px) {
      width: 100%;
    }
  }

  h2 {
    font-size: 1rem;
    font-weight: 900;
    color: #616161;
    text-align: center;
  }

  p {
    font-size: 0.8rem;
    color: #777777;
    width: 60%;
    text-align: center;
    @media (max-width: 487px) {
      width: 100%;
    }
  }

  .button-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 487px) {
    padding: 2rem;
  }
}

.notfound-container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
  gap: 1rem;

  &.empty {
    background-color: #fafafa;

    h2 {
      font-weight: 700 !important;
      font-size: 1.3rem !important;
      color: #616161 !important;
    }
  }

  .spinner {
    margin: 0 auto;
  }

  img {
    max-width: 400px;
    width: auto;
    max-height: 275px;
    height: auto;
    margin-bottom: 1rem;

    @media (max-width: 487px) {
      max-width: unset;
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 900;
    color: #222222;
    text-align: center;
  }

  p {
    font-size: 1rem;
    color: #616161;
    width: 60%;
    text-align: center;
    @media (max-width: 487px) {
      width: 100%;
    }
  }

  .mat-icon {
    color: #a8a8a8;
    font-size: 64px;
    width: 64px;
    height: 64px;
  }

  .button-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 487px) {
    padding: 2rem;
  }
}

.no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 1rem;
  gap: 1rem;

  .text {
    font-size: 1rem;
    color: #616161;
    width: 60%;
    text-align: center;
    @media (max-width: 487px) {
      width: 100%;
    }
  }

  .searched-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: #222222;
  }

  img {
    max-width: 250px;
  }
}

#post-content {
  max-width: 100%;
  word-wrap: break-word !important;
  p {
    font-size: 1rem;
    line-height: 140%;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  * {
    max-width: 100% !important;
  }
}

.custom-label {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0.5rem 0;

  .career-label {
    font-size: 0.7rem;
    font-weight: 700;
    color: #616161;
    color: #616161;
    text-transform: uppercase;
  }
}

/* AVISO INNER HTML */

#aviso-innerhtml {
  .chip {
    padding: 0.2rem 0.5rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    background-color: $primary-color;
    border-radius: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 140%;
    color: #616161;
  }
}

/* SWIPER */

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff !important;
}

.swiper-pagination-bullet {
  background: #ffffff !important;
}

/* BUSCADOR */

.buscador {
  position: sticky;
  width: 300px;
  padding: 0 0.8rem;
  min-height: 48px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: $shadow-100;

  .form-field {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span,
    mat-icon,
    svg {
      color: #757575;
      fill: #757575;
    }

    input {
      flex: 1;
      border: 0px solid;
    }

    .suffix {
      span,
      mat-icon,
      svg {
        color: $primary-color;
        fill: $primary-color;
      }
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
  }
}

/* LOADER */

.small-loader,
.small-loader:after {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.small-loader {
  margin: 0;
  /* position: relative; */
  border-top: 3px solid rgba(150, 150, 150, 0.2);
  border-right: 3px solid rgba(150, 150, 150, 0.2);
  border-bottom: 3px solid rgba(150, 150, 150, 0.2);
  border-left: 3px solid rgb(150, 150, 150);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes increaseFont {
  from {
    font-size: 14px; /* Tamaño de fuente inicial */
  }
  to {
    font-size: 16px; /* Tamaño de fuente final */
  }
}


/* ONE PAGE NAV */

.sticky-header {
  overflow: hidden;
  position: sticky;
  top: 65px;
  z-index: 8 !important;
  box-shadow: $main-shadow;
}

.one-page-nav {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: sticky;
  top: 65px;
  z-index: 8 !important;

  mat-tab-group {
    border-bottom: none;
    color: transparent;
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    box-shadow: none;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.touranchor--is-active {
  border: 2px solid red;
  border-radius: 5px;
}
