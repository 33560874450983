/* Blue */
$blue: #6181ff;
$blue-dark: #4c70ff;
$blue-light: #90a6fc;

$blue-pastel: rgba(236, 240, 255, 1);
$blue-pastel-light: rgba(248, 250, 255, 1);

/* Gray */
$gray: #616161;
$gray-dark: #222222;
$gray-light: #a8a8a8;

/* Red */
$red: #b31d15;
$red-dark: #841510;
$red-light: #d13c34;

$red-pastel: #ffe2e1;
$red-pastel-light: #fff8f7;

/* Green */

$green: #47ba60;
$green-dark: #32a54b;
$green-light: #85d897;

/*  Purple */
$purple: #8b78ff;

/* Success */
$success-green: #35d0a5;
$success-green-dark: #1e8065;
$success-green-pastel: #eafaf6;

/* Error */
$error-red: hsl(347, 100%, 55%);
$error-red-dark: hsl(347, 100%, 40%);
$error-red-pastel: hsl(347, 100%, 95%);

/*  Primary color */
$primary-color: $red;
$primary-color-dark: $red-dark;
$primary-color-light: $red-light;
$primary-color-pastel: $red-pastel;
$primary-color-pastel-light: $red-pastel-light;

/* Accent color */
$accent-color: $gray;
$accent-color-dark: $gray-dark;
$accent-color-light: $gray-light;

/* Impulso */

$impulso-primary: #ecbd00;
$impulso-dark: #ffb228;
$impulso-light: #f1d561;
$impulso-gradient: linear-gradient(
  270deg,
  $impulso-primary 0%,
  $impulso-dark 100%
);

/* Gradients */

$light-gradient: linear-gradient(
  270deg,
  $primary-color-pastel 0%,
  $primary-color-pastel-light 100%
);

$light-gradient-vertical: linear-gradient(
  0deg,
  rgba(254, 246, 246, 1) 0%,
  rgba(254, 246, 246, 0) 100%
);

$light-gradient-horizontal: linear-gradient(
  270deg,
  rgba(254, 246, 246, 1) 0%,
  rgba(254, 246, 246, 0) 100%
);

$bold-gradient: linear-gradient(
  34deg,
  $primary-color-dark 0%,
  $primary-color 50%,
  $primary-color-light 100%
);

$red-light-gradient: linear-gradient(
    89.95deg,
    #ffffff 0.04%,
    #fffdfd 6.99%,
    #fce9e8 99.95%
  ),
  #ffffff;

$green-light-gradient: linear-gradient(90deg, #ffffff 0%, #eafaf4 100%), #ffffff;

/* SHADOWS */

$main-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08),
  0px 6px 12px -6px rgba(24, 39, 75, 0.12);

$shadow-100: 0px 4px 4px -2px rgba(24, 39, 75, 0.08),
  0px 2px 4px -2px rgba(24, 39, 75, 0.12);

$shadow-200: 0px 8px 8px -4px rgba(24, 39, 75, 0.08),
  0px 4px 6px -4px rgba(24, 39, 75, 0.12);

$shadow-700: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
  0px 14px 64px -4px rgba(24, 39, 75, 0.12);

$shadow-800: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
  0px 18px 88px -4px rgba(24, 39, 75, 0.14);
